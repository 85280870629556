@use "styles/mixins/breakpoints" as *;

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.header {
  text-align: center;
}

.errorImage {
  width: 362px;
  height: 245px;
  background-image: url("/img/Page404/404.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-top: 32px;

  @include media-breakpoint-only(xs) {
    margin-top: 18px;
  }

  @include media-breakpoint-down(xs) {
    width: 288px;
    height: 195px;
    margin-top: 24px;
  }
}

.description {
  margin-top: 25px;
  text-align: center;

  @include media-breakpoint-down(xs) {
    margin-top: 24px;
  }
}

.button {
  --button-font-size-medium: 14px;
  
  margin-top: 32px;

  @include media-breakpoint-down(s) {
    margin-top: 24px;
  }

}
